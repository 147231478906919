import styled from "styled-components";

export const Point = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	min-width: 57px;

	p:first-child {
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		color: #fff;
		text-transform: uppercase;

		@media (max-width: 480px) {
			font-size: 12px;
		}
	}

	p:last-child {
		font-size: 18px;
		color: #fff;

		@media (max-width: 480px) {
			font-size: 14px;
		}
	}
`;

export const Rank = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 18px;
	color: #fff;
	span {
		margin-right: 5px;
	}
`;

export const PointsWrapper = styled.div`
	max-width: 35%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 30px;

	@media screen and (max-width: 992px) {
		max-width: 90%;
	}
`;
