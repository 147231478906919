import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Collapse, List, ListItemButton} from "@mui/material";
import {PureButton} from "components/FormElements";
import {getLangData, Languages} from "modules/utils/langs";
import {ReactComponent as ArrowDown} from "assets/img/icons/ArrowDown.svg";
import {userUpdate} from "modules/actions";
import {isLoggedIn} from "modules/selectors";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const MenuButton = styled(PureButton)<{isOpen?: boolean}>`
	display: flex;
	align-items: center;
	color: #fff;
	font-family: var(--fontFamilyPrimary);
	font-size: 16px;
	line-height: 120%;

	svg {
		transition: all 0.3s ease-in-out;
		transform: rotate(${({isOpen}) => (isOpen ? "180" : "0")}deg);
	}
`;

const Flag = styled.img`
	width: 18px;
	height: auto;
	display: block;
	margin-right: 8px;
`;

export const MobileLangSelector: React.FC = () => {
	const dispatch = useDispatch();
	const {i18n, t} = useTranslation();
	const {flagSrc, key, defaultVal} = getLangData(i18n.language);
	const [isOpen, setIsOpen] = React.useState(false);
	const isLogged = useSelector(isLoggedIn);

	const toggleHandler = useCallback(() => {
		setIsOpen((isOpen) => !isOpen);
	}, []);

	const changeLanguage = useCallback(
		(lang: string) => {
			void i18n.changeLanguage(lang);
			toggleHandler();

			if (isLogged) {
				dispatch(userUpdate({lang}));
			}
		},
		[i18n, toggleHandler, dispatch, isLogged]
	);

	return (
		<Wrapper>
			<MenuButton onClick={toggleHandler} isOpen={isOpen}>
				<Flag src={flagSrc} alt={defaultVal} />
				<span>{t(key, defaultVal)}</span>
				<ArrowDown />
			</MenuButton>

			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List className="p-0">
					{Languages.filter(({lang}) => lang !== i18n.language).map(
						({lang, defaultVal, key, flagSrc}) => (
							<ListItemButton key={key} onClick={() => changeLanguage(lang)}>
								<Flag src={flagSrc} alt={key} />
								<span>{t(key, defaultVal)}</span>
							</ListItemButton>
						)
					)}
				</List>
			</Collapse>
		</Wrapper>
	);
};
