import styled from "styled-components";
import {IInput} from "components";
import arrow from "assets/img/accordion-arrow.svg";

export const Select = styled.select<IInput>`
	border-radius: 30px;
	border: 1px solid ${({has_error}) => (has_error ? "#E3137F" : "#B8B8B8")};
	height: 40px;
	width: 100%;
	padding-left: 20px;
	background: #ffffff url("${arrow}") right 20px center no-repeat;
	background-size: 12px;
	font-size: 12px;
	color: #000;

	> option {
		padding-left: 20px;
	}
	appearance: none;
`;

export default Select;
