import React, {useEffect, useState} from "react";
import {User} from "modules/utils/User";
import {Storage} from "modules/utils/Storage";
import {ButtonPrimary} from "components";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const SignBlock = styled.div`
	margin: 40px auto;
	max-width: 260px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: column;
	
	p{
		color: #FFFFFF};
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		font-family: "MotoGPText";
		text-align: center;
	}
	
	input, button{
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const ComingSoonLanding = () => {
	const {t} = useTranslation();
	const [click, updateClick] = useState(0);
	const [show, toggleShow] = useState(false);
	const [val, valChange] = useState("");
	const checkSecret = () => updateClick(click + 1);
	useEffect(() => {
		if (click > 13) {
			toggleShow(true);
		}
	}, [click]);
	useEffect(() => {
		if (val === User.KEY()) {
			Storage.SET("is_admin", true);
			window.location.reload();
		}
	}, [val]);
	const update = (e: React.SyntheticEvent<HTMLInputElement>) => {
		valChange(e.currentTarget.value);
	};

	return (
		<SignBlock>
			<p>{t("landing.page.fantasy_game", "MotoGP™ Fantasy game")}</p>
			{show ? (
				<input type="text" value={val} onChange={update} />
			) : (
				<ButtonPrimary onClick={checkSecret}>
					{t("landing.page.coming_soon", "Coming Soon")}
				</ButtonPrimary>
			)}
		</SignBlock>
	);
};

export default ComingSoonLanding;
