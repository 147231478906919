import styled from "styled-components";

export const LeagueHeaderWrapper = styled.div`
	background-color: #232930;
	color: #ffffff;
	font-family: var(--fontFamilyAccent);
	height: 44px;
	padding: 10px 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;

	p:first-child {
		font-size: 20px;
		letter-spacing: -0.11px;
		line-height: 21px;
		cursor: pointer;

		a {
			display: flex;
			align-items: center;

			img {
				margin-right: 3px;
			}
		}

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	p.commissioner {
		font-size: 12px;
		letter-spacing: 0.09px;
		line-height: 22px;
	}
`;
