import React, {Fragment} from "react";
import {inviteViaEmail} from "../../modules/actions/leagues";
import {IEmailInvite, IEmailInvites, ILeague} from "../../modules/reducers/leagues";
import {eq, get} from "lodash";
import LeagueForm from "./LeagueForm";
import {LeagueFormInvite} from "./LeagueFormInvite";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

interface IProps {
	inactive?: boolean;
	league?: ILeague;
	update?: boolean;
}

export const LeagueInvite: React.FC<IProps> = ({league, inactive, update}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	let league_invite_form_data: IEmailInvites = {
		league_id: 0,
		invites: [],
	};

	const invite_league_title = t("create_league.step_two.header", "Step 2: Invite Your Friends");

	const invite_league_description = () => (
		<Fragment>
			{t(
				"create_league.step_two.copy",
				"Great, now you that you have created your league you can invite others to join.  Your unique league code is:"
			)}{" "}
			{league?.code && <span>{league.code}</span>}
		</Fragment>
	);

	const handleInviteFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();
		league_invite_form_data.league_id = league?.id || 0;

		dispatch(inviteViaEmail(league_invite_form_data));
	};

	const handleInviteFormChange = (fields: IEmailInvite[]) => {
		league_invite_form_data.invites = [...fields];
	};

	const onInvalidInviteForm = (event: any) => {
		const value = get(event, "target.value", "");
		const validation_message = eq(value, "")
			? t(
					"create_league.step_two.provide_email",
					"Please provide your friend's name and email address"
			  )
			: "";

		event.target.setCustomValidity(validation_message);
	};

	if (update && league) {
		return (
			<LeagueForm
				title={t("create_league.step_two.invite_friend", "Invite Your Friends") as string}
				form={
					<LeagueFormInvite
						update={update}
						league={league}
						onSubmit={handleInviteFormSubmit}
						onChange={handleInviteFormChange}
						onInvalid={onInvalidInviteForm}
						description={invite_league_description()}
						inactive={inactive}
					/>
				}
			/>
		);
	}

	return (
		<LeagueForm
			inactive={inactive}
			title_dashed={invite_league_title}
			width="100%"
			form_visible={true}
			form={
				<LeagueFormInvite
					inactive={inactive}
					league={league}
					onSubmit={handleInviteFormSubmit}
					onChange={handleInviteFormChange}
					onInvalid={onInvalidInviteForm}
					description={invite_league_description()}
				/>
			}
		/>
	);
};

export default LeagueInvite;
