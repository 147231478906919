import styled from "styled-components";
import {PureButton} from "components/FormElements/PureButton";

export const ButtonPrimary = styled(PureButton)`
	--hoverBackgroundColor: #cd1173;
	--bgColor: #e3137f;

	background-color: var(--bgColor);
	border-radius: 4px;
	height: 40px;
	color: #ffffff;
	font-family: var(--fontFamilyPrimary);
	font-size: 16px;
	width: 100%;
	cursor: pointer;
	transition: all 250ms ease-in-out;

	&:hover:not(:disabled) {
		background: var(--hoverBackgroundColor);
	}

	&:disabled {
		background: #959595;
		cursor: default;
		color: rgba(255, 255, 255, 0.4);
	}

	&.green {
		--bgColor: #1db72b;
		--hoverBackgroundColor: #0f981c;
	}

	&.flexed {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}

	@media screen and (max-width: 768px) {
		font-size: 14px;
	}
`;

export default ButtonPrimary;
