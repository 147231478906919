import {LeagueForm, LeagueFormCreate} from "components";
import {eq, filter, first, get, last} from "lodash";
import {postLeagueCreate, postLeagueLeave, postLeagueUpdate} from "modules/actions";
import {
	getActualEventID,
	getScheduledEvents,
	getUserId,
	isLeagueCreating,
	isLeagueLeaveSuccess,
	isLeagueUpdated,
} from "modules/selectors";
import {ILeague, ILeagueCreate} from "modules/types";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";

interface IProps {
	inactive?: boolean;
	league?: ILeague;
}

const nameTrigger = ({name, description}: ILeagueCreate) => {
	window.dataLayer.push({
		event: "League",
		eventAction: "Set-up",
		eventLabel: `create a league:${name}:${description}`,
		eventCategory: "click",
	});
};

const privacyTrigger = ({privacy}: ILeagueCreate) => {
	window.dataLayer.push({
		event: "League",
		eventAction: "Set-up",
		eventLabel: `create a league:privacy setting:${privacy}`,
		eventCategory: "click",
	});
};

const startTrigger = ({start_event_id}: ILeagueCreate) => {
	window.dataLayer.push({
		event: "League",
		eventAction: "Set-up",
		eventLabel: `create a league:date:${start_event_id}`,
		eventCategory: "click",
	});
};

const createTrigger = () => {
	window.dataLayer.push({
		event: "League",
		eventAction: "Create",
		eventLabel: "leagues:button:create my league",
		eventCategory: "click",
	});
};

export const LeagueCreate: React.FC<IProps> = ({inactive, league}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const user_id = useSelector(getUserId);
	const is_commissioner = eq(get(league, "user_id"), user_id);

	const [readonly_form, setReadonlyFormState] = useState(true);

	const changeView = () => {
		setReadonlyFormState(!readonly_form);
	};

	const actual_event_id = useSelector(getActualEventID);
	const events = useSelector(getScheduledEvents);
	const first_scheduled_event = first(events);
	const first_scheduled_event_id = get(first_scheduled_event, "id");
	const last_scheduled_event_id = get(last(events), "id");
	const is_league_updated = useSelector(isLeagueUpdated);
	const is_league_leave_success = useSelector(isLeagueLeaveSuccess);
	const [end_events, setEventEvents] = useState(events);
	const is_create_disabled = useSelector(isLeagueCreating);

	const create_league_title = t("create_league.step_one.header", "STEP 1:  CREATE YOUR LEAGUE");
	const create_league_description = t(
		"create_league.step_one.copy",
		"Fill out the short form below to create your very own league."
	);

	const defaultLeague: ILeagueCreate = {
		name: "",
		privacy: "public",
		description: "",
		start_event_id: first_scheduled_event_id || actual_event_id || 1,
		end_event_id: last_scheduled_event_id,
	};

	const [league_form_data, updateLeagueState] = useState(league || defaultLeague);

	useEffect(() => {
		if (league && league.id !== league_form_data.id) {
			updateLeagueState(league);
		}
	}, [league_form_data, league]);

	useEffect(() => {
		setEventEvents(
			filter(events, (event_object) => {
				return event_object.id >= league_form_data.start_event_id;
			})
		);
	}, [events, league_form_data.start_event_id]);

	const handleCreateFormSubmit = useCallback(
		(event: React.SyntheticEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (!is_create_disabled && !inactive) {
				createTrigger();

				dispatch(postLeagueCreate(league_form_data));
			}
		},
		[is_create_disabled, dispatch, league_form_data, inactive]
	);

	const handleUpdateFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (league) {
			dispatch(postLeagueUpdate({...league_form_data, id: league.id}));
		}
	};

	const dataLayerEventFn = {
		name: nameTrigger,
		description: nameTrigger,
		privacy: privacyTrigger,
		start_event_id: startTrigger,
	};

	const handleCreateFormChange = (
		event: React.SyntheticEvent<HTMLFormElement> | SelectChangeEvent
	) => {
		const name = get(event, "target.name", "name");
		const value = get(event, "target.value", "");

		const funcAnalytics = get(dataLayerEventFn, name, nameTrigger);
		if (typeof funcAnalytics === "function") {
			funcAnalytics(league_form_data);
		}

		updateLeagueState({
			...league_form_data,
			[name]: value,
		});
	};

	const onInvalidCreateForm = (event: any) => {
		const name = get(event, "target.name", "");
		const validation_message =
			eq(name, "name") && eq(league_form_data.name, "")
				? t(
						"create_league.validation.provide_league_name",
						"Please provide or change the existing league name"
				  )
				: "";

		event.target.setCustomValidity(validation_message);
	};

	const handleLeagueLeave = () => {
		if (league) {
			dispatch(postLeagueLeave({league_id: league.id}));
		}
	};

	const update_button_text = is_league_updated
		? t("league.edit.updated", "Updated")
		: t("league.edit.update", "Update Your League");

	if (is_league_leave_success) {
		return <Redirect to="/leagues" />;
	}

	if (league) {
		return (
			<LeagueForm
				form={
					<LeagueFormCreate
						league_form_data={league_form_data}
						events={events}
						onSubmit={handleUpdateFormSubmit}
						onChange={handleCreateFormChange}
						onInvalid={onInvalidCreateForm}
						onLeagueLeave={handleLeagueLeave}
						readonly_form={readonly_form}
						button_text={update_button_text}
						is_commissioner={is_commissioner}
						changeView={changeView}
						end_events={end_events}
					/>
				}
				is_commissioner={is_commissioner}
				title={t("league.about.header", "About League") as string}
				onLeagueLeave={handleLeagueLeave}
				readonly_form={readonly_form}
			/>
		);
	}

	return (
		<LeagueForm
			inactive={inactive}
			form_visible={true}
			form={
				<LeagueFormCreate
					events={events}
					onSubmit={handleCreateFormSubmit}
					onChange={handleCreateFormChange}
					onInvalid={onInvalidCreateForm}
					description={create_league_description}
					end_events={end_events}
					league_form_data={league_form_data}
				/>
			}
			title_dashed={create_league_title}
		/>
	);
};

export default LeagueCreate;
