import styled from "styled-components";
import {Container} from "components/Structure";
import {PureButton} from "components/FormElements";

export const Tabs = styled(Container)`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	box-sizing: border-box;

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`;
export const Tab = styled(PureButton)`
	font-family: var(--fontFamilyPrimary);
	font-weight: 700;
	font-size: 12px;
	height: 54px;
	line-height: 100%;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	border-bottom: 6px solid transparent;
	color: #fff;
	padding: 0 20px;
	transition: all 250ms ease-in-out;
	box-sizing: border-box;
	&:hover,
	&.selected {
		color: var(--primaryColor);
		border-bottom-color: var(--primaryColor);
	}
	@media screen and (max-width: 768px) {
		height: 50px;
		font-family: var(--fontFamilyAccent);
		font-size: 14px;
		font-weight: 500;
	}

	@media screen and (max-width: 425px) {
		padding: 0 12px;
	}
`;
