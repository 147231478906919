import * as React from "react";
import styled from "styled-components";
import checked_icon from "assets/img/checked.svg";
import {uniqueId} from "lodash";

interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
	readonly onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	readonly children: React.ReactNode;
	readonly name: string;
	readonly id?: string;
	readonly tick_position?: string;
	readonly type?: string;
	readonly as?: any;
	readonly is_radio?: boolean;
	readonly width?: string;

	is_on_light?: boolean;
}

const StyledCheckbox = styled.div`
	margin: 10px auto;
	width: 100%;
	overflow: hidden;
	position: relative;
	min-height: 25px;
`;

interface ICheckboxStyled {
	readonly tick_position?: string;
}

const Checkbox = styled.input`
	position: absolute;
	${({tick_position}: ICheckboxStyled) => tick_position}: 0;
	z-index: 0;
	opacity: 0;
`;

interface ILabel extends ICheckboxStyled {
	readonly is_radio?: boolean;
	readonly width?: string;
	is_on_light?: boolean;
}

const Label = styled.label`
	padding-${({tick_position}: ILabel) => tick_position}: 2.7em;
	position: relative;
	line-height: 1.4;
	font-size: 12px;
	color: ${({is_on_light}) => (is_on_light ? "#232323" : "#ffffff")};
	vertical-align: baseline;
	text-align: left;
	box-sizing: border-box;
	width: 100%;
	display: inline-block;
	
	a{
		text-decoration: underline;
	}
	
	${({is_radio}: ILabel) =>
		is_radio
			? `
		display: inline-flex;
		align-items: center;
	`
			: ""}
	
	&:before {
		position: absolute;
		${({tick_position}: ILabel) => tick_position}: 0;
		top: 1px;
		display: block;
		content: '';
		border: 2px solid ${({is_on_light}) => (is_on_light ? "#232323" : "#ffffff")};
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		background-color: transparent;
		
		${Checkbox}:checked + & {
			background-image: url(${checked_icon});
			background-size: 1em;
			background-repeat: no-repeat;
			background-position: center;
			border-color: var(--primaryColor);
		}
	} 
`;

export const CheckBox: React.FC<ICheckbox> = (props) => {
	const {
		onChange,
		children,
		tick_position = "right",
		type = "checkbox",
		id = uniqueId("checkbox"),
		width,
		is_on_light,
		...rest
	} = props;

	return (
		<StyledCheckbox>
			<Checkbox
				onChange={onChange}
				id={id}
				type={type}
				tick_position={tick_position}
				{...rest}
			/>
			<Label
				htmlFor={id}
				is_radio={type === "radio"}
				tick_position={tick_position}
				is_on_light={is_on_light}
				width={width}>
				{children}
			</Label>
		</StyledCheckbox>
	);
};

export default CheckBox;
