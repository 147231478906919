import {IConstructor, IStore} from "modules/types";
import {createSelector} from "reselect";
import {get, memoize, sortBy} from "lodash";
import {SortColumnOrder, SortColumnType} from "modules/utils";
import {getConstructorsWithMarkedInTeam} from "modules/selectors";

export const getSortConstructorsTableParams = (state: IStore) => state.constructors_table;

export const getSortedConstructors = createSelector(
	getSortConstructorsTableParams,
	getConstructorsWithMarkedInTeam,
	(params, constructors) =>
		memoize((search: string, prev_gp_id: number) => {
			const un_sorted_constructors =
				constructors.filter(({name}) => `${name}`.toLowerCase().includes(search || "")) ||
				[];

			const keys = {
				[SortColumnType.ActualPoints]: `stats.events[${prev_gp_id}].points`,
				[SortColumnType.Price]: "cost",
				[SortColumnType.Status]: "",
				[SortColumnType.Podiums]: "",
			};

			const key = keys[params.sort] || keys[SortColumnType.ActualPoints];

			const sorted = sortBy(un_sorted_constructors, (constructor: IConstructor) => {
				return get(constructor, key, 0);
			});

			return params.order === SortColumnOrder.ASC ? sorted : sorted.reverse();
		})
);
