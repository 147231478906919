import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	min-height: 40px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	justify-content: center;
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 120%;
	font-family: var(--fontFamilyPrimary);

	div {
		display: flex;
		align-items: center;

		img {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 992px) {
		font-size: 14px;

		div {
			display: block;
			&:first-child {
				margin-right: 10px;
			}
		}
		img {
			margin-right: 0;
		}
		padding: 10px 20px;
		box-sizing: border-box;
	}
`;

export const SponsorInfo: React.FC = () => {
	const {t} = useTranslation();

	return (
		<Wrapper>
			<div>
				{t(
					"team.boost.description",
					"MotoGP™ Fantasy boost gives you a competitive edge by doubling one of your riders’ points in 3 races this season"
				)}
			</div>
		</Wrapper>
	);
};
