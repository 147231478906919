import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {Menu, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {PureButton} from "components/FormElements";
import {getLangData, Languages} from "modules/utils/langs";
import {userUpdate} from "modules/actions";
import {ReactComponent as ArrowDown} from "assets/img/icons/ArrowDown.svg";
import {isLoggedIn} from "modules/selectors";

const Wrapper = styled.div`
	margin-left: 16px;
	display: flex;
	align-items: center;
`;

const MenuButton = styled(PureButton)<{isOpen?: boolean}>`
	display: flex;
	align-items: center;
	color: #fff;
	font-family: var(--fontFamilyPrimary);
	font-size: 16px;
	line-height: 120%;

	svg {
		transition: all 0.3s ease-in-out;
		transform: rotate(${({isOpen}) => (isOpen ? "180" : "0")}deg);
	}
`;

const Flag = styled.img`
	width: 18px;
	height: auto;
	display: block;
	margin-right: 8px;
`;

export const LangSelector: React.FC = () => {
	const dispatch = useDispatch();
	const {i18n, t} = useTranslation();
	const {flagSrc, key, defaultVal} = getLangData(i18n.language);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const isLogged = useSelector(isLoggedIn);

	const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const changeLanguage = useCallback(
		(lang: string) => {
			void i18n.changeLanguage(lang);
			handleClose();

			if (isLogged) {
				dispatch(userUpdate({lang}));
			}
		},
		[i18n, handleClose, dispatch, isLogged]
	);

	return (
		<Wrapper>
			<MenuButton onClick={handleClick} isOpen={open}>
				<Flag src={flagSrc} alt={defaultVal} />
				<span>{t(key, defaultVal)}</span>
				<ArrowDown />
			</MenuButton>

			<Menu
				disablePortal
				open={open}
				id="lang-selector"
				anchorEl={anchorEl}
				onClose={handleClose}>
				{Languages.filter(({lang}) => lang !== i18n.language).map(
					({lang, defaultVal, key, flagSrc}) => (
						<MenuItem key={lang} onClick={() => changeLanguage(lang)}>
							<Flag src={flagSrc} alt={key} />
							<span>{t(key, defaultVal)}</span>
						</MenuItem>
					)
				)}
			</Menu>
		</Wrapper>
	);
};
