import React, {Fragment} from "react";
import {get} from "lodash";
import {ButtonPrimary} from "components";
import styled from "styled-components";
import {ReactourStepContentArgs} from "reactour";
import {partial} from "lodash";
import step_1 from "assets/img/tour/1.png";
import step_2 from "assets/img/tour/2.png";
import step_3 from "assets/img/tour/3.png";
import step_4 from "assets/img/tour/4.png";
import step_5 from "assets/img/tour/5.png";
import step_6 from "assets/img/tour/6.png";
import step_7 from "assets/img/tour/7.png";
import {ReactComponent as NextIcon} from "assets/img/icons/nextIcon.svg";
import {useTranslation} from "react-i18next";

const Step = styled.div`
	box-sizing: border-box;
	h2 {
		font-family: var(--fontFamilyAccent);
		color: #000000;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 5px;
		text-transform: uppercase;
		text-align: center;
	}

	p {
		font-family: var(--fontFamilyPrimary);
		color: #000000;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 120%;
		text-align: center;
		margin-bottom: 50px;
	}
	img {
		display: none;

		@media screen and (max-width: 992px) {
			display: block;
			margin: 0 auto 20px;
		}
	}
`;

const steps = {
	0: {
		titleKey: "",
		title: "",
		contentKey: "",
		content: "",
		is_last: false,
		img: "",
	},
	1: {
		titleKey: "tutorial.step_one.title",
		title: "HOW TO BUILD YOUR TEAM",
		contentKey: "tutorial.step_one.copy",
		content:
			"Select a team of four riders, one constructor, and one team. Your Gold Riders score 100% points while your Silver Riders scoring 50% points",
		is_last: false,
		img: step_1,
	},
	2: {
		titleKey: "tutorial.step_two.title",
		title: "POINTS SCORING",
		contentKey: "tutorial.step_two.copy",
		content:
			"Based on riders' real performances during a GP weekend, you will accumulate Fantasy points",
		is_last: false,
		img: step_2,
	},
	3: {
		titleKey: "tutorial.step_three.title",
		title: "POINTS BOOST",
		contentKey: "tutorial.step_three.copy",
		content:
			"Use the boost to double your rider scoring, but be careful, you only have 3 to use throughout the season.",
		is_last: false,
		img: step_3,
	},
	4: {
		titleKey: "tutorial.step_four.title",
		title: "TRADES",
		contentKey: "tutorial.step_four.copy",
		content:
			"After each GP you have 2 trades you can make, which can include a combination of riders and constructor from your team by pressing the orange button.",
		is_last: false,
		img: step_4,
	},
	5: {
		titleKey: "tutorial.step_five.title",
		title: "change constructors and teams",
		contentKey: "tutorial.step_five.copy",
		content:
			"You can remove Constructors and Teams by clicking the ‘X’ button. You can then replace them with another Constructor or Team in the player pool below.",
		is_last: false,
		img: step_5,
	},
	6: {
		titleKey: "tutorial.step_six.title",
		title: "TRADES",
		contentKey: "tutorial.step_six.copy",
		content:
			"Once you trade a rider out of your team, head down to the rider pool to select a rider you can afford based on your remaining budget",
		is_last: false,
		img: step_6,
	},
	7: {
		titleKey: "tutorial.step_seven.title",
		title: "JOIN/CREATE LEAGUES",
		contentKey: "tutorial.step_seven.copy",
		content:
			"Challenge your friends in public or private leagues for more fun! The more the merrier, so share it with them!",
		is_last: true,
		img: step_7,
	},
};

interface IProps extends ReactourStepContentArgs {}

export const TourSteps: React.FC<IProps> = ({step, goTo, close}) => {
	const {t} = useTranslation();
	const {title, titleKey, content, contentKey, img, is_last} = get(steps, step, steps[0]);

	const lastStepAction = () => {
		close();
		goTo(0);
	};

	return (
		<Step>
			<img src={img} alt={title} />
			<h2>{t(titleKey, title)}</h2>
			<p>{t(contentKey, content)}</p>
			<ButtonPrimary
				className="flexed"
				onClick={is_last ? lastStepAction : partial(goTo, step)}>
				{is_last ? (
					t("tutorial.button.start_playing", "Start Playing")
				) : (
					<Fragment>
						{t("tutorial.button.next", "Next tip")} <NextIcon />
					</Fragment>
				)}
			</ButtonPrimary>
		</Step>
	);
};
