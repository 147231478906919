import React, {ChangeEvent, useState} from "react";
import styled from "styled-components";
import {CopyButton, ButtonPrimary, LeagueFormDescription, SocialButtons, Exist} from "components";
import {IEmailInvite, ILeague} from "modules/types";
import {isInvitesSended} from "modules/selectors";
import {map, partial, size, pullAt, gt, get, lt} from "lodash";
import plus from "assets/img/round-plus.svg";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {InputAdornment, TextField} from "@mui/material";
import cross from "assets/img/cross.svg";
import {useMediaQuery} from "modules/utils";
import {useTranslation} from "react-i18next";

const AddButton = styled.div`
	height: 30px;
	width: 30px;
	background-image: url("${plus}");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	margin: 10px 0 25px;
`;

const LeagueInviteWrapper = styled.form`
	${ButtonPrimary} {
		margin: 30px 0;
	}
	.invites {
		max-width: 220px;
	}

	@media screen and (max-width: 992px) {
		${AddButton} {
			margin: 15px auto 25px;
		}

		> ${ButtonPrimary} {
			max-width: 100%;
		}
	}
`;

const Title = styled.div`
	font-family: "MotoGPText";
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	margin-bottom: 15px;
`;

const Invite = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	> div:last-child {
		flex: 2;
		margin-left: 20px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		> div:first-child {
			margin-bottom: 10px;
		}
		> div:last-child {
			margin-left: 0;
			margin-bottom: 20px;
		}
	}
`;

const NavLinkStyled = styled(NavLink)`
	text-decoration: underline;
	margin-top: 35px;
	display: block;
	color: #fff;
`;
const ClearBtn = styled.div`
	background-image: url("${cross}");
	background-repeat: no-repeat;
	background-position: center;
	width: 40px;
	height: 40px;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	cursor: pointer;
`;

interface IProps {
	league?: ILeague;
	onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
	onChange: (fields: IEmailInvite[]) => void;
	onInvalid: (e: React.SyntheticEvent<HTMLFormElement>) => void;
	description?: string | React.ReactElement;
	update?: boolean;
	inactive?: boolean;
}

export const LeagueFormInvite = ({
	league,
	onSubmit,
	onChange,
	onInvalid,
	description,
	update,
	inactive,
}: IProps) => {
	const {t} = useTranslation();
	const is_mobile = useMediaQuery("(max-width: 768px)");
	const is_invites_sent = useSelector(isInvitesSended);
	const [members, setMembersState] = useState([
		{
			first_name: "",
			email: "",
		},
	]);

	const addFields = () => {
		const members_copy = [...members];
		members_copy.push({first_name: "", email: ""});
		setMembersState(members_copy);
	};

	const removeFields = (field_index: number) => {
		let members_copy = [...members];

		if (gt(size(members_copy), 1) && lt(size(members_copy), 10)) {
			setMembersState(pullAt(members_copy, field_index));
			onChange(members_copy);
		}
	};

	const handleChange = (event: ChangeEvent) => {
		const id = +get(event, "target.dataset.id", "");
		const name: "email" | "first_name" = get(event, "target.dataset.name", "email");
		const value = get(event, "target.value", "");

		let members_copy = [...members];

		members_copy[id][name] = value;

		setMembersState(members_copy);

		onChange(members);
	};

	return (
		<LeagueInviteWrapper onSubmit={onSubmit} onInvalid={onInvalid}>
			<LeagueFormDescription>{description}</LeagueFormDescription>
			<CopyButton value={league?.code || ""} />

			<Title>{t("create_league.step_two.invite_email", "Invite via email:")}</Title>

			{map(members, (member, index) => (
				<Invite key={index}>
					<TextField
						fullWidth={is_mobile}
						label={t("create_league.step_two.email_first_name", "First Name")}
						name={`${index}-first_name`}
						data-id={index}
						data-name="first_name"
						required={true}
						onChange={handleChange}
					/>
					<TextField
						fullWidth={is_mobile}
						label={t("create_league.step_two.email_address", "Email Address")}
						name={`${index}-email`}
						data-id={index}
						data-name="email"
						required={true}
						onChange={handleChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Exist when={index > 0}>
										<ClearBtn onClick={partial(removeFields, index)} />
									</Exist>
								</InputAdornment>
							),
						}}
					/>
				</Invite>
			))}

			<AddButton onClick={addFields} />

			<ButtonPrimary className="invites" type="submit" disabled={is_invites_sent}>
				{is_invites_sent
					? t("create_league.step_two.email_sent", "SENT")
					: t("create_league.step_two.send_invites", "SEND INVITES")}
			</ButtonPrimary>

			<Title>{t("create_league.step_two.invite_social", "Invite via social:")}</Title>
			<SocialButtons update={update} league={league} />

			{!inactive && !update && (
				<NavLinkStyled to={"/leagues"}>
					{t("create_league.step_two.skip", "Skip this step")}
				</NavLinkStyled>
			)}
		</LeagueInviteWrapper>
	);
};

export default LeagueFormInvite;
