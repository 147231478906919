import React from "react";
import styled, {css} from "styled-components";

const Input = styled.input`
	position: absolute;
	left: -99999px;
	opacity: 0;
`;

interface ILabelTextProps {
	isActive: boolean;
}

const LabelText = styled.span<ILabelTextProps>`
	display: flex;
	width: 50%;
	flex: 0 0 50%;
	align-items: center;
	justify-content: center;
	color: var(--primaryColor);
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	height: 100%;
	border: 1px solid transparent;
	box-sizing: border-box;
	user-select: none;

	${({isActive}) =>
		isActive
			? css`
					background: var(--primaryColor);
					color: #fff;
					border: 1px solid var(--primaryColor);
					border-radius: 13% / 50%;
					box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
			  `
			: ""}
`;

const Label = styled.label`
	background: #fff;
	border: 1px solid var(--primaryColor);
	position: relative;
	overflow: hidden;
	width: 320px;
	height: 40px;
	display: flex;
	border-radius: 7% / 50%;
	cursor: pointer;
`;

interface IProps {
	labelOne: string;
	labelTwo: string;
	checked: boolean;
	onClick?: () => any;
}

export const Switch: React.FC<IProps> = ({labelOne, labelTwo, checked, onClick, ...rest}) => (
	<Label {...rest}>
		<Input onChange={onClick} type="checkbox" checked={checked} />
		<LabelText isActive={!checked}>{labelOne}</LabelText>
		<LabelText isActive={checked}>{labelTwo}</LabelText>
	</Label>
);

export default Switch;
