import * as React from "react";
import {Timer} from "./index";
import {padStart} from "lodash";
import styled from "styled-components";
import {Fragment} from "react";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	@media screen and (max-width: 1000px) {
		gap: 4px;
	}
`;

const TimeUnit = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	font-size: 16px;

	@media screen and (max-width: 1000px) {
		font-size: 12px;
	}
`;

const Value = styled.div`
	font-family: var(--fontFamilyAccent);
	padding: 5px 0;
`;

const Label = styled.div`
	font-family: var(--fontFamilyAccent);
	text-transform: uppercase;
`;

interface ITimeUnitContainerProps {
	isVisible?: boolean;
	className?: string;
	children: any;
}

const TimeUnitContainer: React.FC<ITimeUnitContainerProps> = ({
	isVisible = true,
	children,
	...rest
}) => (isVisible ? <TimeUnit {...rest}>{children}</TimeUnit> : null);

const toTimerValue = (num: number) => padStart(String(num), 2, "0");

const ONE_HOUR = 1000 * 60 * 60;

interface IProps {
	date?: string | number | Date;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
}

export const LockoutTimer = ({date, onComplete, onTick}: IProps) => {
	const {t} = useTranslation();

	return (
		<Fragment>
			{date ? (
				<Timer onTick={onTick} onComplete={onComplete} date={date}>
					{({days, hours, minutes, seconds, diff}) => {
						const is_less_hour = diff < ONE_HOUR;

						return (
							<Wrapper>
								<TimeUnitContainer isVisible={!is_less_hour}>
									<Value>{days}</Value>
									<Label>{t("timer.days", "d")}</Label>
								</TimeUnitContainer>
								<TimeUnitContainer className="hours-block">
									<Value>{toTimerValue(hours)}</Value>
									<Label>{t("timer.hours", "h")}</Label>
								</TimeUnitContainer>
								<TimeUnitContainer className="minutes-block">
									<Value>{toTimerValue(minutes)}</Value>
									<Label>{t("timer.minutes", "m")}</Label>
								</TimeUnitContainer>
								<TimeUnitContainer
									className="seconds-block"
									isVisible={is_less_hour}>
									<Value>{toTimerValue(seconds)}</Value>
									<Label>{t("timer.seconds", "s")}</Label>
								</TimeUnitContainer>
							</Wrapper>
						);
					}}
				</Timer>
			) : null}
		</Fragment>
	);
};
