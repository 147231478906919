import styled from "styled-components";

export const LeagueButtonLeave = styled.p`
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.1px;
	text-align: left;
	width: 100%;
	margin-bottom: 10px;
	color: var(--primaryColor);
	text-decoration: underline;
	cursor: pointer;
	margin-top: 30px;
`;

export default LeagueButtonLeave;
