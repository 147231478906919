import React from "react";
import styled, {css} from "styled-components";
import {ButtonPrimary, WhatsAppShareButtonWrapper} from "components";
import facebook from "assets/img/facebook.svg";
import x from "assets/img/icons/x.svg";
import {share, ShareNet, ShareSource, shareWhatsApp} from "modules/utils";
import {ILeague} from "modules/types";
import {partial} from "lodash";
import {WhatsappShareButton} from "react-share";
import {useTranslation} from "react-i18next";

const getSocialSharingButtonsStyles = ({update}: {update?: boolean}) =>
	update
		? css`
				max-width: 100%;

				> ${ButtonPrimary} {
					max-width: 32%;
					background-position: 10% center;
					font-size: 14px;
					@media screen and (max-width: 420px) {
						font-size: 12px;
					}
				}
				.whatsapp-wrapper {
					max-width: 32%;
					button {
						font-size: 14px;
						background-position: 7% center;
					}
				}
		  `
		: "";

const SocialButtonsWrapper = styled.div<{update?: boolean}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	> button {
		flex: 1;
		margin: 0;
		background-position: 15% center;
		font-size: 14px;
	}

	.whatsapp-wrapper {
		flex: 1;
		margin: 0;
		button {
			font-size: 14px;
			background-position: 12% center;
		}
	}

	${getSocialSharingButtonsStyles};

	@media screen and (max-width: 420px) {
		> button {
			font-size: 12px;
			justify-content: flex-end;
			background-position: 10% center;
		}
		.whatsapp-wrapper {
			button {
				font-size: 12px !important;
				padding-left: 15px !important;
			}
		}
	}
`;

const FacebookButton = styled(ButtonPrimary)`
	background-color: #3c5998;
	background-image: url("${facebook}");
	background-size: 8px;
	background-repeat: no-repeat;
	//background-position: 30% center;
	&:hover:not(:disabled) {
		background-color: #3c5998;
		background-image: url("${facebook}");
		background-size: 8px;
		background-repeat: no-repeat;
		background-position: 15% center;
	}
`;

const TwitterButton = styled(ButtonPrimary)`
	background-color: #6faedc;
	background-image: url("${x}");
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: 30% center;
	&:hover:not(:disabled) {
		background-color: #6faedc;
		background-image: url("${x}");
		background-size: 20px;
		background-repeat: no-repeat;
		background-position: 15% center;
	}
`;

interface IProps {
	league?: ILeague;
	update?: boolean;
}

export const SocialButtons: React.FC<IProps> = ({league, update}) => {
	const {t} = useTranslation();
	const message = t(
		"create_league.step_two.share_message",
		`I've created a new league on MotoGP™ Fantasy Game. Join here to be apart of the league: {{X}}`,
		{X: league?.code}
	); // TODO update share
	const share_data = {
		sc: ShareNet.WhatsApp,
		league_id: league?.id,
		section: ShareSource.League,
		message,
	};

	const whatsApp = shareWhatsApp(share_data);

	const handleShare = (type_share: ShareNet) => {
		share({
			...share_data,
			sc: type_share,
		});
	};

	return (
		<SocialButtonsWrapper update={update}>
			<FacebookButton type="button" onClick={partial(handleShare, ShareNet.Facebook)}>
				{t("create_league.step_two.facebook", "Facebook")}
			</FacebookButton>
			<TwitterButton type="button" onClick={partial(handleShare, ShareNet.Twitter)}>
				{t("create_league.step_two.x", "X")}
			</TwitterButton>
			<WhatsAppShareButtonWrapper className={"whatsapp-wrapper"}>
				<WhatsappShareButton {...whatsApp}>
					{t("create_league.step_two.whatsapp", "Whatsapp")}
				</WhatsappShareButton>
			</WhatsAppShareButtonWrapper>
		</SocialButtonsWrapper>
	);
};

export default SocialButtons;
