import React from "react";
import styled from "styled-components";
import {eq} from "lodash";

const PrivacyRadioWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0;
	justify-content: space-between;
	position: relative;
	color: #fff;
	background-color: transparent;
	border: 1px solid var(--primaryColor);
	height: 40px;
	width: 300px;
	box-sizing: border-box;

	> * {
		height: 40px;
		display: block;
		width: 50%;
		margin: 0;
		box-sizing: border-box;
		cursor: pointer;
		position: absolute;
		top: 0;
	}

	> label {
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		border: 1px solid transparent;
		text-transform: uppercase;
		font-weight: 700;

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}

	> input {
		opacity: 0;
		z-index: 1;

		&:checked + label {
			background-color: var(--primaryColor);
			z-index: 3;
		}
	}
`;

interface IPrivacyRadio {
	required?: boolean;
	defaultChecked?: string;
	labelOne: string;
	labelTwo: string;
	name: string;
	onChange?: () => void;
}

export const RadioSwitcher: React.FC<IPrivacyRadio> = ({
	required,
	defaultChecked,
	labelOne,
	labelTwo,
	onChange,
	name,
}) => (
	<PrivacyRadioWrapper>
		<input
			required={required}
			type="radio"
			id={labelOne.toLocaleLowerCase()}
			name={name}
			value={labelOne.toLocaleLowerCase()}
			onChange={onChange}
			defaultChecked={eq(labelOne.toLocaleLowerCase(), defaultChecked)}
		/>
		<label htmlFor={labelOne.toLocaleLowerCase()}>{labelOne}</label>
		<input
			required={required}
			type="radio"
			id={labelTwo.toLocaleLowerCase()}
			name={name}
			value={labelTwo.toLocaleLowerCase()}
			defaultChecked={eq(labelTwo.toLocaleLowerCase(), defaultChecked)}
			onChange={onChange}
		/>
		<label htmlFor={labelTwo.toLocaleLowerCase()}>{labelTwo}</label>
	</PrivacyRadioWrapper>
);

export default RadioSwitcher;
