import styled, {css} from "styled-components";
import stats from "assets/img/stats.svg";
import stats_dark from "assets/img/stats-dark.svg";
import React from "react";
import fire from "assets/img/icons/fire.svg";
import plus from "assets/img/rounded_plus.svg";
import info from "assets/img/icons/disqualified.svg";

import {get} from "lodash";
import {IRider} from "modules/reducers/riders";
import {PureButton} from "components/FormElements";
import {StatusTooltip} from "components/Tooltip";

interface ICardContainerProps {
	has_selected?: boolean;
	is_selected?: boolean;
	background?: string;
	is_swap_enabled?: boolean;
}

const getCardContainerStateStyles = ({
	has_selected,
	is_selected,
	is_swap_enabled,
}: ICardContainerProps) =>
	(has_selected && !is_selected) || is_swap_enabled
		? css`
				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.56);
					z-index: 2;
				}
		  `
		: "";

export const CardContainerWrapper = styled.div`
	height: 258px;
	width: 187px;
	position: relative;
	@media screen and (max-width: 960px) {
		width: 154px;
		height: 200px;
	}
`;

export const CardContainer = styled.div<ICardContainerProps>`
	background-image: url(${({background}) => background});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 258px;
	width: 187px;
	color: #fff;
	box-sizing: border-box;
	padding: 5px;
	overflow: hidden;
	position: relative;
	${getCardContainerStateStyles};

	&.pointer {
		cursor: pointer;
	}
	@media screen and (max-width: 960px) {
		width: 154px;
		min-width: 154px;
		height: 200px;
		background-image: url(${({background}) => background});
	}
`;

export const CardContainerWrapperHalf = styled.div`
	height: 108px;
	width: 187px;
	position: relative;
	&.in-swap {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.56);
			z-index: 1002;
		}
	}
	@media screen and (max-width: 960px) {
		width: 154px;
		height: 92px;
	}
`;
export const CardContainerHalf = styled.div<ICardContainerProps>`
	height: 108px;
	width: 170px;
	color: #fff;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	padding: 2px;
	background: url(${({background}) => background}) center no-repeat;
	background-size: contain;
	border-radius: 8px;

	&.empty {
		background: linear-gradient(180deg, #43505b 0%, #141922 100%);
		position: relative;
		&:after {
			content: "";
			height: 104px;
			width: 166px;
			border-radius: 8px;
			box-sizing: border-box;
			display: block;
			border: 2px solid #fff;
			position: absolute;
			top: 0;
			@media screen and (max-width: 960px) {
				width: 150px;
				height: 88px;
			}
		}
	}

	${getCardContainerStateStyles};

	@media screen and (max-width: 960px) {
		width: 154px;
		height: 92px;
	}
`;

export const CardInner = styled.div`
	position: absolute;
	bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 0 10px;
	left: 0;
	box-sizing: border-box;
`;

export const CardInnerHalf = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 8px;
	//border: 2px solid #fff;
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
`;

export const CardName = styled.h3`
	margin-bottom: 8px;
	vertical-align: middle;
	font-size: 22px;
	font-weight: 500;
	font-family: var(--fontFamilyAccent);
	text-align: center;

	&.aside-card {
		font-size: 16px;
	}

	&.player-name {
		font-size: 12px;
		line-height: 120%;

		strong {
			font-size: 14px;
		}
	}

	strong {
		text-transform: uppercase;
	}

	@media screen and (max-width: 960px) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;

		&.player-name {
			font-size: 9px;

			strong {
				font-size: 11px;
			}
		}
	}
`;

export const CenterPlacing = styled(CardName)`
	margin: auto;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	position: absolute;
	z-index: 3;
	font-size: 18px;
	line-height: 24px;
`;

export const CardActionButton = styled.button`
	background: var(--primaryColor);
	width: 100%;
	height: 28px;
	margin: 0 auto;
	box-sizing: border-box;
	border: 0;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: background 0.5s linear;

	&:hover:not(:disabled) {
		background: #cd1173;
	}

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

interface ICardActionSwapButtonProps {
	active?: boolean;
	available?: boolean;
}

const getCardActionSwapButtonStateStyles = ({active, available}: ICardActionSwapButtonProps) => {
	if (active) {
		return css`
			background: none;
			border-color: var(--primaryColor);
		`;
	}

	if (available) {
		return css`
			animation: readyForSwap 1s linear infinite;
		`;
	}
	return css``;
};

export const CardActionSwapButton = styled(CardActionButton)<ICardActionSwapButtonProps>`
	border: 1px solid transparent;

	@keyframes readyForSwap {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.3;
		}

		100% {
			opacity: 1;
		}
	}

	${getCardActionSwapButtonStateStyles};
`;
export const CardSubInfo = styled.p`
	color: #b9babb;
	font-size: 12px;
	line-height: 120%;
	text-align: center;
	margin-bottom: 5px;

	@media screen and (max-width: 960px) {
		margin-bottom: 3px;
	}
`;

export const CardPrice = styled.div`
	font-size: 26px;
	font-family: var(--fontFamilyAccent);
	font-weight: 500;

	&.small {
		font-size: 20px;
	}

	&.mb-8 {
		margin-bottom: 8px;
	}

	@media screen and (max-width: 960px) {
		font-size: 20px;
	}
`;

interface ICardPoints {
	is_live?: boolean;
}

const getLiveColor = ({is_live}: ICardPoints) =>
	css`
		${is_live ? "#1DB72B" : "#fff"}
	`;

export const CardPoints = styled(CardPrice)<ICardPoints>`
	display: flex;
	align-items: flex-end;
	gap: 4px;
	p {
		color: ${getLiveColor};
		font-family: var(--fontFamilyAccent);
		font-size: 26px;
	}
	span {
		font-family: var(--fontFamilyAccent);
		font-weight: normal;
		color: ${getLiveColor};
		font-size: 22px;
	}

	@media screen and (max-width: 768px) {
		p {
			font-size: 20px;
		}
		span {
			font-size: 18px;
		}
	}
`;

const getLiveUrl = ({is_live}: ICardPoints) => (is_live ? stats : stats_dark);

export const CardPointsIcon = styled.div<ICardPoints>`
	background: url(${getLiveUrl}) no-repeat center;
	background-size: cover;
	width: 28px;
	height: 28px;
	box-sizing: border-box;
	margin-bottom: -3px;
`;

export const CardStatusWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	color: #ffffff;
	width: 40px;
	position: absolute;
	top: 40px;
	left: 10px;
	font-size: 12px;

	> img {
		margin-left: 10px;
		display: block;
	}
	&.popup {
		left: 80px;
		top: 35px;
		@media screen and (max-width: 640px) {
			top: 10px;
		}
	}
`;

interface ICardStatus {
	status: IRider["status"];
	from_popup?: boolean;
}
const rider_status = {
	active: {},
	on_fire: {
		text: "Rider on fire",
		image: fire,
		pool_image: fire,
	},
	injured: {
		text: "Rider is injured",
		image: plus,
		pool_image: plus,
	},
	disqualified: {
		text: "Rider is unavailable",
		image: info,
		pool_image: info,
	},
};

export const CardStatus: React.FC<ICardStatus> = ({status, from_popup}) => {
	const rider_status_image = get(rider_status, `${status}.image`, "");
	const rider_status_text = get(rider_status, `${status}.text`, "");
	const className = from_popup ? "popup" : "";
	const placement = from_popup ? "top" : "right";
	if (status === "active") {
		return null;
	}

	return (
		<StatusTooltip title={rider_status_text} placement={placement} arrow>
			<CardStatusWrapper className={className}>
				<img src={rider_status_image} alt={rider_status_text} />
			</CardStatusWrapper>
		</StatusTooltip>
	);
};

export const RiderStatus: React.FC<ICardStatus> = ({status}) => {
	const rider_status_image = get(rider_status, `${status}.pool_image`, "");
	const rider_status_text = get(rider_status, `${status}.text`, "");

	return (
		<StatusTooltip title={rider_status_text} placement="top" arrow>
			<img src={rider_status_image} alt={rider_status_text} />
		</StatusTooltip>
	);
};

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	button {
		max-width: 50%;
		width: 200px;
	}
`;

export const ButtonRemove = styled(PureButton)`
	position: absolute;
	right: 5px;
	top: -10px;
	width: 40px;
	height: 40px;
	z-index: 2;
	@media screen and (max-width: 1000px) {
		right: -10px;
	}
	&.rider {
		right: -15px;
		top: -10px;
	}
`;
