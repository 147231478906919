import React from "react";
import {useSelector} from "react-redux";
import {CardContainerHalf, CardContainerWrapperHalf, CardInnerHalf, CardPoints} from "components";
import {getSquadsById} from "modules/selectors";
import {Constant} from "modules/utils";
import {get} from "lodash";
import {useTranslation} from "react-i18next";

interface IProps {
	unitID: number;
	event_id: number;
}
export const TeamCardShow: React.FC<IProps> = ({unitID, event_id}) => {
	const {t} = useTranslation();
	const squads_by_id = useSelector(getSquadsById);
	const squad = squads_by_id[unitID];
	if (!squad) {
		return <CardContainerHalf className={"empty"} />;
	}

	const {
		id,
		stats: {events},
	} = squad;
	const scores = get(events, event_id, {points: "-"});
	return (
		<CardContainerWrapperHalf>
			<CardContainerHalf background={Constant.TEAMS_URL + id + "_1.png"}>
				<CardInnerHalf>
					<CardPoints>
						<p>{scores.points} </p>
						<span>{t("rider.card.pts", "PTS")}</span>
					</CardPoints>
				</CardInnerHalf>
			</CardContainerHalf>
		</CardContainerWrapperHalf>
	);
};
