import {
	CardContainer,
	CardContainerWrapper,
	CardInner,
	CardName,
	CardPoints,
	CardStatus,
	CardSubInfo,
} from "components/TeamField";
import {Constant, RIDER_IMAGE_VERSION} from "modules/utils/Constant";
import {get, isNumber} from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {getAllRidersById} from "modules/selectors/riders";
import cardBg from "assets/img/team/cardBg.png";
import mobileCardBg from "assets/img/team/mobileCardBg.png";
import {useMediaQuery} from "modules/utils";
import {useTranslation} from "react-i18next";

interface IProps {
	unitID: number;
	turboID: number | null;
	event_id: number;
	is_bench: boolean;
}

export const RiderCardShow: React.FC<IProps> = ({unitID, turboID, event_id, is_bench}) => {
	const {t} = useTranslation();
	const riders_by_id = useSelector(getAllRidersById);
	const rider = riders_by_id[unitID];
	const is_mobile = useMediaQuery("(max-width: 960px)");

	if (!rider) {
		const bg = is_mobile ? mobileCardBg : cardBg;
		return <CardContainer background={bg} />;
	}

	const {
		first_name,
		last_name,
		squad,
		id,
		stats: {events},
		status,
	} = rider;
	const scores = get(events, event_id, {points: "-"});
	let score = isNumber(scores.points) && is_bench ? scores.points / 2 : scores.points;
	const is_turbo = turboID === unitID;

	score = isNumber(score) && is_turbo ? score * 2 : score;

	return (
		<CardContainerWrapper>
			<CardContainer
				background={`${Constant.RIDERS_URL + id}_1.png?v=${RIDER_IMAGE_VERSION}`}
				data-tut="second_step">
				<CardStatus status={status} />

				<CardInner>
					<CardName>
						<span>{first_name}</span>
						<br />
						<strong>{last_name}</strong>
					</CardName>
					<CardSubInfo>{squad ? squad.name : ""}</CardSubInfo>
					<CardPoints>
						<p>{score} </p>
						<span>{t("rider.card.pts", "PTS")}</span>
					</CardPoints>
				</CardInner>
			</CardContainer>
		</CardContainerWrapper>
	);
};
