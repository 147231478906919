import facebook from "assets/img/facebook.svg";
import logo from "assets/img/footer_logo.svg";
import x from "assets/img/icons/x.svg";
import instagram from "assets/img/instagram.svg";
import youtube from "assets/img/youtube.svg";
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {StyledLink} from "../Links";
import {Container} from "../Structure";
import {ReactComponent as GSLogo} from "assets/img/icons/GS.svg";
import {ReactComponent as MainLogo} from "assets/img/footer_game_logo.svg";
import {useTranslation} from "react-i18next";

const FooterWrapper = styled.footer`
	background: #000;
	color: #fff;
	padding: 70px 0 45px;
	margin-top: auto;

	img {
		display: block;
	}

	@media screen and (max-width: 500px) {
		padding: 32px 0 40px;
	}
`;

const Nav = styled.nav`
	margin-bottom: 50px;

	@media screen and (max-width: 500px) {
		text-align: center;
		margin-bottom: 35px;
	}
`;

const NavItem = styled(StyledLink)`
	margin-right: 15px;
	font-size: 14px;

	&:last-child {
		margin-right: 0;
	}

	@media screen and (max-width: 768px) {
		font-size: 12px;
	}
`;

const CopyrightWrapper = styled.div`
	font-size: 12px;
	line-height: 18px;
	max-width: 1000px;
	margin-bottom: 50px;

	@media screen and (max-width: 500px) {
		text-align: center;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.logos {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	@media screen and (max-width: 500px) {
		flex-direction: column;
		gap: 27px;
	}
`;

const SiteLogo = styled.a`
	&.logo img {
		width: 116px;
	}

	&.site-logo svg {
		width: 171px;
	}

	@media screen and (max-width: 500px) {
		margin: 0;

		&.logo img {
			width: 87px;
		}

		&.site-logo svg {
			width: 135px;
		}
	}
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		margin-left: 15px;

		.facebook-link {
			height: 22px;
		}

		.instagram-link {
			height: 24px;
		}

		.twitter-link {
			width: 24px;
		}

		.youtube-link {
			width: 24px;
		}
	}

	p {
		color: #ffffff;
		font-size: 14px;
	}

	margin-bottom: 30px;
`;

const NavWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		flex-flow: column;
		align-items: center;

		> ${Socials} {
			order: -1;
		}
	}
`;

const currentYear = new Date().getFullYear();

export const Footer: React.FC = () => {
	const {t} = useTranslation();

	return (
		<FooterWrapper>
			<Container>
				<NavWrapper>
					<Nav>
						<NavItem as={Link} to="/help/rules">
							{t("footer.legal.conditions", "Legal conditions")}
						</NavItem>
						<NavItem
							rel="noopener noreferrer"
							target="_blank"
							href="https://sso.dorna.com/en/privacy-policy">
							{t("footer.privacy.policy", "Privacy Policy")}
						</NavItem>

						<NavItem
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.motogp.com/en/Terms+of+Use">
							{t("footer.terms.conditions", "Terms & Conditions")}
						</NavItem>
					</Nav>
					<Socials>
						<p>{t("footer.follow.us", "Follow us on:")}</p>
						<a
							href="https://www.facebook.com/groups/177512103696364"
							target="_blank"
							rel="noopener noreferrer">
							<img src={facebook} alt="facebook" className={"facebook-link"} />
						</a>
						<a
							href="https://www.instagram.com/motogp/"
							target="_blank"
							rel="noopener noreferrer">
							<img src={instagram} alt="instagram" className={"instagram-link"} />
						</a>
						<a
							href="https://twitter.com/MotoGP"
							target="_blank"
							rel="noopener noreferrer">
							<img src={x} alt="x" className={"twitter-link"} />
						</a>
						<a
							href="https://www.youtube.com/user/MotoGP"
							target="_blank"
							rel="noopener noreferrer">
							<img src={youtube} alt="youtube" className={"youtube-link"} />
						</a>
					</Socials>
				</NavWrapper>
				<CopyrightWrapper>
					<p>
						{t(
							"footer.disclainer.copy",
							`© {{X}} Dorna Sports SL. All rights reserved. All trademarks are the property of their respective owners.`,
							{X: currentYear}
						)}
					</p>
				</CopyrightWrapper>
				<LogoWrapper>
					<div className="logos">
						<SiteLogo className="logo" href="https://www.motogp.com/">
							<img src={logo} alt="MOTOGP™" />
						</SiteLogo>
						<SiteLogo href="/" className="site-logo">
							<MainLogo />
						</SiteLogo>
					</div>

					<a
						className="left-align"
						href="https://geniussports.com/media-engagement/free-to-play-games/"
						rel="noopener noreferrer"
						target="_blank">
						<GSLogo />
					</a>
				</LogoWrapper>
			</Container>
		</FooterWrapper>
	);
};
