import {isNumber} from "lodash";
import i18n from "i18next";

const getSignSymbol = (show_sign: boolean, sign: number) => {
	if (!show_sign) {
		return sign === -1 ? "-" : "";
	}

	return sign === -1 ? "-" : "+";
};

interface IProps {
	input?: string | number;
	currency?: string;
	show_sign?: boolean;
}

export const currency = (params: IProps) => {
	const {input, currency = "$", show_sign = false} = params;

	if (!isNumber(input)) {
		return input || "";
	}

	const million = 10 ** 6,
		thousand = 10 ** 3,
		number = parseInt(String(input), 10),
		sign = Math.sign(number),
		sign_symbol = getSignSymbol(show_sign, sign);

	let letter = "",
		abs = Math.abs(number);

	const is_number_thousand = abs < million,
		is_number_million = abs >= million;

	if (is_number_thousand) {
		abs = abs / thousand;
		letter = i18n.t("game_bar.currency.k", "k");
	} else if (is_number_million) {
		abs = abs / million;
		letter = i18n.t("game_bar.currency.m", "m");
	}

	return sign_symbol + " " + currency + abs.toFixed(2) + letter;
};

export default currency;
