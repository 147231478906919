import React, {RefObject, useRef, useState} from "react";
import styled from "styled-components";
import {get} from "lodash";
import cross from "assets/img/cross.svg";

interface IInputStyled {
	has_error?: boolean;
}

export interface IInput extends IInputStyled {
	clear?: boolean;
	callback?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

export const InputStyled = styled.input<IInputStyled>`
	background-color: #ffffff;
	border-radius: 30px;
	border: 1px solid ${({has_error}) => (has_error ? "#E3137F" : "#B8B8B8")};
	padding-left: 20px;
	font-size: 12px;
	color: #232323;
	line-height: 14px;
	height: 40px;
	width: 100%;
	box-sizing: border-box;
	outline: none;
`;

const InputWrapper = styled.div`
	position: relative;
`;

const ClearBtn = styled.div`
	background-image: url("${cross}");
	background-repeat: no-repeat;
	background-size: 60%;
	background-position: center;
	width: 20px;
	height: 20px;
	right: -20px;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	cursor: pointer;
`;

export const Input: React.FC<any> = ({has_error, clear, callback, value, index, ...rest}) => {
	const [can_clear, setClearState] = useState(false);
	const ref: RefObject<any> = useRef();

	const handleKeyUp = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const value = get(event, "target.value", "");
		setClearState(!!value.length);
	};

	const handleClear = () => {
		ref.current.value = "";
		setClearState(false);
	};

	return (
		<InputWrapper>
			<InputStyled
				ref={ref}
				defaultValue={value}
				has_error={has_error}
				{...rest}
				onKeyUp={handleKeyUp}
			/>

			{clear && can_clear && <ClearBtn onClick={handleClear} />}

			{callback && index !== 0 && <ClearBtn onClick={callback} />}
		</InputWrapper>
	);
};

export default Input;
