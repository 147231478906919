import React from "react";
import {IconClose} from "components/Icons";
import {
	ModalOverlay,
	ButtonPrimary,
	WhatsAppShareButtonWrapper,
	ButtonSecondary,
} from "components/";
import styled from "styled-components";
import {partial} from "lodash";
import facebook from "assets/img/facebook.svg";
import x from "assets/img/icons/x.svg";
import {share, SHARE_MESSAGE_GLOBAL, ShareNet, ShareSource, shareWhatsApp} from "modules/utils";
import {WhatsappShareButton} from "react-share";
import modal_banner from "assets/img/banner.png";
import {useTranslation} from "react-i18next";

const doNothing = (event: React.SyntheticEvent<HTMLDivElement>) => {
	event.preventDefault();
	event.stopPropagation();
};

const CloseModalButton = styled.button`
	background: none;
	padding: 0;
	border: 0;
	position: absolute;
	right: 0;
	top: -30px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ModalInner = styled.div`
	background: #fff;
	width: 100%;
	max-width: 414px;
	box-sizing: border-box;
	max-height: 100%;
	max-height: 100vh;
	position: relative;
	padding-bottom: 30px;
`;

const ModalHeader = styled.header`
	width: 100%;
	background: url(${modal_banner});
	background-size: cover;
	height: 70px;
	font-family: "MotoGPText";
	font-weight: 900;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 27px;
	letter-spacing: 0;
	line-height: 50px;
`;

const ModalShareIn = styled.div``;
const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	> button {
		margin: 0 auto 15px;
		width: 280px;
		max-width: 100%;
	}
`;
const Title = styled.div`
	color: #232323;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
`;
const FacebookButton = styled(ButtonPrimary)`
	background: url("${facebook}") 30% center no-repeat #3c5998;
	background-size: 8px;
	&:hover:not(:disabled) {
		background: url("${facebook}") 30% center no-repeat #3c5998;
		background-size: 8px;
	}
`;

const TwitterButton = styled(ButtonPrimary)`
	background: url("${x}") 30% center no-repeat #6FAEDC;
	background-size: 20px;
	&:hover:not(:disabled) { {
		background: url("${x}") 30% center no-repeat #6FAEDC;
		background-size: 20px;
	}
`;

const ButtonClose = styled(ButtonSecondary)`
	//height: 24px;
	width: 280px;
	//color: #C80502;
	//font-family: 'MotoGPText';
	//font-size: 14px;
	margin: 20px auto 10px;
	display: block;
	//letter-spacing: 0;
	//line-height: 19px;
	//text-align: center;
	//background: transparent;
	//border: none;
`;

interface IProps {
	closeModal: () => void;
}

export const ModalShare: React.FC<IProps> = ({closeModal}) => {
	const {t} = useTranslation();
	const share_data = {
		sc: ShareNet.WhatsApp,
		section: ShareSource.General,
		message: SHARE_MESSAGE_GLOBAL,
	};
	const handleShare = (type_share: ShareNet) => {
		share({
			...share_data,
			sc: type_share,
		});
	};

	const whatsApp = shareWhatsApp(share_data);

	return (
		<ModalOverlay onClick={closeModal}>
			<ModalInner onClick={doNothing}>
				<CloseModalButton onClick={closeModal}>
					<IconClose color={"#fff"} />
				</CloseModalButton>
				<ModalHeader />
				<ModalShareIn>
					<Title>
						{t(
							"modal.share.title",
							"Where would you like to share MotoGP™ Fantasy to?"
						)}
					</Title>
					<ButtonsContainer>
						<FacebookButton
							type="button"
							onClick={partial(handleShare, ShareNet.Facebook)}>
							{t("modal.share.fb", "Facebook")}
						</FacebookButton>
						<TwitterButton
							type="button"
							onClick={partial(handleShare, ShareNet.Twitter)}>
							{t("modal.share.x", "X")}
						</TwitterButton>
						<WhatsAppShareButtonWrapper>
							<WhatsappShareButton {...whatsApp}>
								{t("modal.share.whatsapp", "Whatsapp")}
							</WhatsappShareButton>
						</WhatsAppShareButtonWrapper>
					</ButtonsContainer>

					<ButtonClose className="red" onClick={closeModal}>
						{t("modal.share.close", "Close")}
					</ButtonClose>
				</ModalShareIn>
			</ModalInner>
		</ModalOverlay>
	);
};
