import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getEventById, getUserTeamState} from "modules/selectors";
import {map} from "lodash";
import {RiderCardShow} from "./RiderCardShow";
import {ConstructorCardShow} from "./ConstructorCardShow";
import styled from "styled-components";
import {clearUserTeam} from "modules/actions/team";
import {ModalOverlay} from "components/Modal";
import {IconClose} from "components/Icons/Close";
import field_background from "assets/img/team/teamFieldBg.jpeg";
import goldRiderIcon from "assets/img/team/goldRiderIcon.png";
import silverRiderIcon from "assets/img/team/silverRiderIcon.png";
import {Tooltip} from "components/Tooltip";
import {
	constructor_team_tooltip,
	reserve_team_tooltip,
	squad_team_tooltip,
	start_team_tooltip,
} from "modules/utils";
import {TeamCardShow} from "components/UserLineupModal/TeamCardShow";
import {useTranslation} from "react-i18next";

const ModalInner = styled.div`
	box-sizing: border-box;
	background: url(${field_background}) center no-repeat;
	background-size: cover;
	padding: 20px 40px 25px;
	color: #fff;
	position: relative;
	z-index: 20;
	border-radius: 4px;
	width: 100%;
	max-width: 1080px;

	@media screen and (max-width: 1000px) {
		height: 100%;
		overflow: scroll;
		width: 100%;
		padding: 20px 10px 25px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TeamFieldWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 20px;

	@media screen and (max-width: 1000px) {
		flex-flow: column;
		justify-content: center;
		box-sizing: border-box;
	}
`;

const StartingTeam = styled.div`
	@media screen and (max-width: 1000px) {
		margin-bottom: 25px;
	}
`;

const ReserveRiders = styled.div``;

const FieldTitle = styled.h4`
	font-size: 18px;
	margin-bottom: 22px;

	@media screen and (max-width: 1000px) {
		text-align: center;
	}
`;

const ItemsList = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	&.first-small {
		margin-bottom: 8px;
	}
`;

const CloseButton = styled.button`
	background: none;
	padding: 0;
	border: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
`;

const TeamName = styled.p`
	color: #fff;
	font-family: "MotoGPText";
	font-size: 40px;
	font-weight: 900;
	margin-bottom: 10px;
`;

const TrackInfo = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	h3 {
		font-weight: 900;
		font-size: 16px;
		line-height: 21px;
		text-transform: uppercase;
		margin-right: 10px;
	}

	p {
		font-size: 16px;
	}

	@media screen and (max-width: 1000px) {
		display: block;
	}

	@media screen and (max-width: 480px) {
		h3 {
			font-size: 14px;
			margin-bottom: 5px;
		}

		p {
			font-size: 10px;
		}
	}
`;

const InfoTooltip = styled(Tooltip)`
	margin-left: 10px;
`;
const SmallCards = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media screen and (max-width: 1000px) {
		flex-direction: row;
	}
`;

const doNothing = (event: React.SyntheticEvent<HTMLDivElement>) => {
	event.preventDefault();
	event.stopPropagation();
};

export const UserLineupModal: React.FC<{event_id: number}> = ({event_id}) => {
	const user_team = useSelector(getUserTeamState);
	const event = useSelector(getEventById(event_id));
	const dispatch = useDispatch();
	const {t} = useTranslation();

	useEffect(() => {
		document.body.style.overflow = user_team.name ? "hidden" : "unset";
	}, [user_team.name]);

	const close = useCallback(() => {
		dispatch(clearUserTeam());
	}, [dispatch]);

	if (!user_team.name || !event) {
		return null;
	}

	const {lineup, turbo_rider, name} = user_team;
	const {riders, constructors, squads, riders_reserve} = lineup;

	return (
		<ModalOverlay onClick={close}>
			<ModalInner onClick={doNothing}>
				<CloseButton onClick={close}>
					<IconClose color={"#fff"} />
				</CloseButton>
				<TeamName>{name}</TeamName>
				<TrackInfo>
					<h3>{event.name}</h3>
					<p>{event.circuit}</p>
				</TrackInfo>
				<Wrapper>
					<TeamFieldWrapper id="team-field">
						<StartingTeam>
							<FieldTitle>
								<img src={goldRiderIcon} alt="" role="presentation" />
								{t("team.gold_riders.title", "GOLD RIDERS")}{" "}
								<InfoTooltip
									text={
										t(
											start_team_tooltip.key,
											start_team_tooltip.defValue
										) as string
									}
								/>
							</FieldTitle>
							<ItemsList>
								{map(riders, (id, index) => (
									<RiderCardShow
										unitID={id}
										event_id={event.id}
										turboID={turbo_rider}
										key={id || index}
										is_bench={false}
									/>
								))}
							</ItemsList>
						</StartingTeam>
						<ReserveRiders>
							<FieldTitle>
								<img src={silverRiderIcon} alt="" role="presentation" />
								{t("team.silver_riders.title", "SILVER RIDERS")}{" "}
								<InfoTooltip
									text={
										t(
											reserve_team_tooltip.key,
											reserve_team_tooltip.defValue
										) as string
									}
								/>
							</FieldTitle>
							<ItemsList>
								{riders_reserve.map((id, index) => (
									<RiderCardShow
										unitID={id}
										event_id={event.id}
										turboID={turbo_rider}
										key={id || index}
										is_bench={true}
									/>
								))}
							</ItemsList>
						</ReserveRiders>

						<SmallCards>
							<div>
								<FieldTitle>
									{t("team.constructor.title", "Constructor")}{" "}
									<InfoTooltip
										text={
											t(
												constructor_team_tooltip.key,
												constructor_team_tooltip.defValue
											) as string
										}
									/>
								</FieldTitle>
								<ItemsList data-tut="fifth_step">
									{constructors.map((id, index) => (
										<ConstructorCardShow
											unitID={id}
											key={id || index}
											event_id={event.id}
										/>
									))}
								</ItemsList>
							</div>
							<div>
								<FieldTitle>
									{t("team.team.title", "Team")}{" "}
									<InfoTooltip
										text={
											t(
												squad_team_tooltip.key,
												squad_team_tooltip.defValue
											) as string
										}
									/>
								</FieldTitle>
								<ItemsList>
									{squads.map((id, index) => (
										<TeamCardShow
											unitID={id}
											key={id || index}
											event_id={event.id}
										/>
									))}
								</ItemsList>
							</div>
						</SmallCards>
					</TeamFieldWrapper>
				</Wrapper>
			</ModalInner>
		</ModalOverlay>
	);
};
