import {Language} from "modules/utils/enums";
import en from "assets/img/flags/EN.svg";
import es from "assets/img/flags/ES.svg";
import it from "assets/img/flags/IT.svg";
import fr from "assets/img/flags/FR.svg";
import de from "assets/img/flags/DE.svg";
import id from "assets/img/flags/ID.svg";
import ja from "assets/img/flags/JA.svg";
import {first} from "lodash";

export const FLAGS: Record<string, string> = {
	[Language.EN]: en,
	[Language.ES]: es,
	[Language.IT]: it,
	[Language.FR]: fr,
	[Language.DE]: de,
	[Language.ID]: id,
	[Language.JA]: ja,
};

export const getFlag = (lang: string) => FLAGS[lang] || FLAGS[Language.EN];

export const Languages = [
	{
		key: "language.en",
		defaultVal: "English",
		lang: Language.EN,
		flagSrc: en,
	},
	{
		key: "language.de",
		defaultVal: "Germany",
		lang: Language.DE,
		flagSrc: de,
	},
	{
		key: "language.fr",
		defaultVal: "French",
		lang: Language.FR,
		flagSrc: fr,
	},
	{
		key: "language.jp",
		defaultVal: "Japanese",
		lang: Language.JA,
		flagSrc: ja,
	},
	{
		key: "language.es",
		defaultVal: "Spanish",
		lang: Language.ES,
		flagSrc: es,
	},
	{
		key: "language.id",
		defaultVal: "Indonesian",
		lang: Language.ID,
		flagSrc: id,
	},
	{
		key: "language.it",
		defaultVal: "Italian",
		lang: Language.IT,
		flagSrc: it,
	},
];

export const getLangData = (language: string) =>
	Languages.find(({lang}) => lang === language) ||
	(first(Languages) as (typeof Languages)[number]);
