import {ISquad, IStore} from "modules/types";
import {createSelector} from "reselect";
import {get, memoize, sortBy} from "lodash";
import {SortColumnOrder, SortColumnType} from "modules/utils";
import {getTeamsWithMarkedInTeam} from "modules/selectors";

export const getSortTeamsTableParams = (state: IStore) => state.teams_table;

export const getSortedTeams = createSelector(
	getSortTeamsTableParams,
	getTeamsWithMarkedInTeam,
	(params, teams) =>
		memoize((search: string, prev_gp_id: number) => {
			const un_sorted_teams = teams.filter(({name}) =>
				`${name}`.toLowerCase().includes(search || "")
			);
			const keys = {
				[SortColumnType.ActualPoints]: `stats.events[${prev_gp_id}].points`,
				[SortColumnType.Price]: "cost",
				[SortColumnType.Status]: "",
				[SortColumnType.Podiums]: "",
			};

			const key = keys[params.sort] || keys[SortColumnType.ActualPoints];

			const sorted = sortBy(un_sorted_teams, (squad: ISquad) => {
				return get(squad, key, 0);
			});

			return params.order === SortColumnOrder.ASC ? sorted : sorted.reverse();
		})
);
